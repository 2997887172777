import React, { useMemo, FC } from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'react-styled-flexboxgrid';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Card, { CmsCard } from '../components/Card';

const Page: FC<{
  data: {
    cms: {
      cards: CmsCard[];
    };
  };
  pageContext: {
    title: string;
    slug: string;
  };
}> = ({ pageContext, data }) => {
  const { slug } = pageContext;
  const cards = useMemo(
    () => data.cms.cards.filter((card) => card.menu && card.menu.slug === slug),
    [data, slug]
  );
  return (
    <Layout>
      <SEO title={pageContext.title} />
      <Grid>
        {cards.map((card) => (
          <Card key={card.id} {...card} />
        ))}
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query {
    cms {
      cards {
        content
        title
        header
        map
        photos {
          id
          title
          image {
            url
          }
        }
        teachers(sort: "order:asc") {
          id
          name
          description
          photo {
            url
          }
        }
        menu {
          id
          slug
        }
      }
    }
  }
`;

export default Page;
